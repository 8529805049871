import { useEffect, useState } from 'react';
import imgClose from '../../../../assets/images/myPlan/imgClose.png';
import { Button } from '../../../ui-core';
import './styles.scss';
import { rateWorkout } from '../../../../services/api/workoutsService/workoutsService';
import { useDispatch } from 'react-redux';
import { errorHandler } from '../../../toastify/redux/notifyOperations';
import { getHintsStatus, putHintsStatus } from '../../../../services/api/profileService/profileService';


export const RatePopUp = ({ onClose, data, t }) => {
    const dispatch = useDispatch();
    const [usersRate, setUsersRate] = useState(null);
    const [showHint, setShowHint] = useState(false);

    useEffect(() => {
        if (usersRate) {
            rateWorkout(usersRate, data.day, data.workoutId)
                .then(response => {
                    if (data?.callback) {
                        data.callback(response.action);
                    } else {
                        onClose && onClose();
                    }
                })
                .catch(error => dispatch(errorHandler(error)));
        }
    }, [usersRate]);

    useEffect(() => {
        getHintsStatus().then(data => {
            setShowHint(data);
        });
    }, []);

    const hintHandler = () => {
        putHintsStatus({
            ...showHint,
            ratePopup: false,
        }).then(data => setShowHint(data));
    };

    const closeHandler = () => {
        if (data?.callback && !data.preventReload) {
            data.callback();
        } else {
            onClose && onClose();
        }
    };

    return (
        <>
            {showHint.ratePopup && (
                <span className={'next_hint'} onClick={hintHandler}>
                    Next
                </span>
            )}

            {/* ========= rate pop up start =========== */}
            <div className="default-modal">
                <img onClick={closeHandler} src={imgClose} className="default-modal-close" alt={'close'} />
                <p className="default-modal-title">How was your workout?</p>
                <p className="popup-select-description">
                    Please rate the difficulty from 1-5 <span>(5 being the hardest).</span>
                </p>
                <div className="popup-select-container">
                    <div
                        className={`popup-select-container-hint${showHint.ratePopup ? ' hint rate-popup__hint' : ''}`}
                    />
                    <Button
                        onClick={setUsersRate.bind(null, 1)}
                        className={`popup-select-rate${usersRate === 1 ? ' popup-selected-rate' : ''}`}
                    >
                        1
                    </Button>
                    <Button
                        onClick={setUsersRate.bind(null, 2)}
                        className={`popup-select-rate${usersRate === 2 ? ' popup-selected-rate' : ''}`}
                    >
                        2
                    </Button>
                    <Button
                        onClick={setUsersRate.bind(null, 3)}
                        className={`popup-select-rate${usersRate === 3 ? ' popup-selected-rate' : ''}`}
                    >
                        3
                    </Button>
                    <Button
                        onClick={setUsersRate.bind(null, 4)}
                        className={`popup-select-rate${usersRate === 4 ? ' popup-selected-rate' : ''}`}
                    >
                        4
                    </Button>
                    <Button
                        onClick={setUsersRate.bind(null, 5)}
                        className={`popup-select-rate${usersRate === 5 ? ' popup-selected-rate' : ''}`}
                    >
                        5
                    </Button>
                </div>
                <Button onClick={closeHandler} className={'popup-select-submit-not-now'}>
                    {' '}
                    Not now
                </Button>
            </div>
            {/* ========= rate pop up end =========== */}
        </>
    );
};
